function Form(selector) {

	this.selector = selector;

	this.init = function() {



		this.clickListeners();

	}

	this.clickListeners = function() {

		$(this.selector).on('click','.checkbox-option', function() {


			if($(this).find('input').is(':checked')) {

				$(this).find('input').prop('checked',false)
				$(this).find('.checkbox').removeClass('checked');

			} else {

				$(this).find('input').prop('checked',true)
				$(this).find('.checkbox').addClass('checked');
				
			}

		});

		$(this.selector).on('click','.radio-option', function() {

			console.log('click!');

			if(!$(this).find('input').is(':checked')) {

				$(this).parent().find('input').prop('checked',false);
				$(this).parent().find('.radio').removeClass('checked');

				$(this).find('input').prop('checked',true)
				$(this).find('.radio').addClass('checked');

			}

		});

	}

	this.init();

}