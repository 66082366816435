var Challenges = function(selector) {
	
	this.selector = selector;
	this.current = false;

	this.challenges = [];

	this.compiledTemplate = _.template($('.template-challenges').val());
	this.compiledTemplateDetail = _.template($('.template-challenge-detail').val());

	this.init = function() {

		this.getData();
		this.setHandlers();

	}

	this.getData = function(url) {


		$.get('/data/challenges.json')
		.error(function (jqXHR, textStatus, errorThrown) {

	        console.log(jqXHR);
	        console.log(textStatus);
	        console.log(errorThrown);

	    })
		.done(function(data){

			this.challenges = data.challenges;

			var html = this.compiledTemplate(data);
			
			$(this.selector).find('.challenge-list').html(html);

		}.bind(this));
	}

	this.setHandlers = function() {

		var that = this;

		$(this.selector).find('.challenge-list').on('click','.ix-show-detail', function(){

			var profile = $(this).parents('.challenge').data('item');

			that.showProfile(profile);


		});

		$(this.selector).find('.challenge-detail').on('click','.ix-back', function(){

			that.closeProfile();


		});

	}

	this.showProfile = function(id) {

		var profile = this.challenges[id];
		this.current = profile;

		var html = this.compiledTemplateDetail(profile);
		$(this.selector).find('.challenge-detail').html(html);

		this.openProfile();

	}

	this.openProfile = function() {
		$(this.selector).addClass('showdetail');
	}

	this.closeProfile = function() {
		this.current = false;
		$(this.selector).removeClass('showdetail');
	}



	this.init();
}