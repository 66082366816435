var Video = function(selector) {

	this.selector = selector;

	this.init = function() {
		this.eventHandlers();
	}

	this.eventHandlers = function() {

		var that = this;

		$(this.selector).click(function(){

			console.log('clicked!');

			if($(this).hasClass('video-loaded')) return;

			var youtubeId = $(this).data('youtube-id');

			var iframeHtml = that.iframeHtml(youtubeId);

			$(that.selector).find('.video-content').html(iframeHtml);

		});
	}

	this.iframeHtml = function(id) {

		return '<iframe src="https://www.youtube-nocookie.com/embed/' + id + '?controls=0&amp;showinfo=0&autoplay=1" frameborder="0"></iframe>';

	}

	this.init();

}