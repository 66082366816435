var Timeline = function(selector) {
	
	this.selector = selector;

	this.init = function() {
		this.setupWaypoints();
	}

	this.setupWaypoints = function() {

		$(this.selector).find('.time-item').waypoint(function(direction){

			$(this.element).addClass('show');

		},{
			offset: '-200px'
		})
	}

	this.init();

}