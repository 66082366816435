var Menu = function(selector) {

	this.selector = selector;

	this.init = function() {

		this.clickHandlers();
	}

	this.clickHandlers = function() {

		$(this.selector).find('.ix-toggle-menu').click(function(){

			if($('body').hasClass('showmenu')) {

				$('body').removeClass('showmenu');
				$(this.selector).find('.icon-menu').removeClass('active');

			} else {

				$('body').addClass('showmenu');
				$(this.selector).find('.icon-menu').addClass('active');

			}
		}.bind(this));

	}

	this.init();
}